.experience-section {
  margin: 20px;
}

.experience-title {
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-family: "Outfit", sans-serif;
  font-weight: bold;
}

.experience-line {
  border-left: 1px solid #333;
  padding-left: 10px;
  position: relative;
}
.experience-container {
  display: flex;
  align-items: flex-start;
  margin: 20px 0;
}

.experience-circle {
  width: 10px;
  height: 10px;
  background-color: #333;
  border-radius: 50%;
  margin-right: 20px;
  position: relative;
  top: 8px;
}

.experience-content {
  flex: 1;
}

.experience-title h4 {
  margin: 0;
  font-size: 20px;
}

.experience-subtitle {
  margin: 5px 0;
  font-size: 15px;
  color: #666;
  font-family: "Outfit", sans-serif;
  font-weight: 400;
}

.experience-list {
  margin: 10px 0;
  padding-left: 20px;  
}

.experience-item {
  margin-bottom: 10px;
  font-size: 14px;
  font-family: "Outfit", sans-serif;
}



.icon-section {
  margin-bottom: 2rem;
}

.icon-line {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.icon-container {
  position: relative;
  margin: 0 auto;
}

.icon {
  height: 50px;
  width: 50px;
}

.icon-tooltip {
  display: none;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 0.25rem;
  border-radius: 4px;
  font-size: 0.75rem;
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  text-align: center;
  white-space: nowrap;
}

.icon-container:hover .icon-tooltip {
  display: block;
}
