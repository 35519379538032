.highlight-container,
.highlight {
  position: relative;
}

.highlight-container {
  display: inline-block;
}
.highlight-container:before {
  content: " ";
  display: block;
  height: 90%;
  width: 100%;
  margin-left: -3px;
  margin-right: -3px;
  position: absolute;
  background: #6a61fa;
  transform: rotate(2deg);
  top: -1px;
  left: -1px;
  border-radius: 20% 25% 20% 24%;
  padding: 10px 3px 3px 10px;
}

.about-me-container {
  text-align: center;
}

.about-me-container .about-me-title {
  padding: 1.5rem;
  font-size: 2.1em;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .about-me-container {
    text-align: left;
  }
  .aboutme-image {
    max-height: 350px;
    max-width: 350px;
  }
  .about-me-container .about-me-title {
    padding: 0;
  }
  .about-me-container .about-me-text {
    margin-top: 2.5rem;
  }
  .about-me-skills-container {
    justify-content: space-between;
  }
}

#NavButton{
  color: #6a61fa;
}

#NavButton::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #6a61fa;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

#NavButton:hover::after {
  transform: scaleX(1);
  transform-origin: bottom right;
}