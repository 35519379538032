
html {
  scroll-behavior: smooth;
  font-size: 16px;
}

body {
  font-family: 'Outfit', sans-serif;
  padding: 0;
  margin: 0 50px;
  overflow-x: hidden;
}

@media screen and (max-width: 768px) {
  body {
    margin: 0 20px;
  }
}

hr {
  border: 1px solid #2d3436;
  width: 70%;
}


h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-family: 'Outfit', sans-serif;
}


/* custome the scrollbar */
::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background: #dfe6e9;
}

::-webkit-scrollbar-thumb {
  background-color: #2d3436;
  border-radius: 20px;
  border: 1px solid #aaa;
}


.Nav {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.01);
  border-radius: 0px 0px 12px 12px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(13.6px);
  -webkit-backdrop-filter: blur(13.6px);
  
}



